<template>
  <div>
    <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
        spinner-mediam
      >
      <transition
      :name="$store.state.appConfig.layout.routerTransition"
       mode="out-in"
        >
          <b-row>
              <b-col md="4">
                <b-card>
                    <b-row>
                      <!-- name -->
                      <b-col cols="12">
                          <b-form-group
                              :label="$t('Name')"
                              label-for="v-name"
                              label-cols-md="4"
                          >
                         {{shopper.name}}
                          </b-form-group>
                      </b-col>
                      <!-- email -->
                      <b-col cols="12">
                          <b-form-group
                              :label="$t('Email')"
                              label-for="v-email"
                              label-cols-md="4"
                          >
                          {{shopper.email}}
                          </b-form-group>
                      </b-col>
                      <!-- mobile -->
                      <b-col cols="12">
                          <b-form-group
                              :label="$t('Mobile')"
                              label-for="v-mobile"
                              label-cols-md="4"
                          >
                          {{shopper.mobile}}
                          </b-form-group>
                      </b-col>
                      <!-- created at -->
                      <b-col cols="12">
                          <b-form-group
                              :label="$t('Shopper since')"
                              label-for="v-created_at"
                              label-cols-md="4"
                          >
                          {{shopper.created_at | formatDateWithoutHours}}
                          </b-form-group>
                      </b-col>
                    </b-row>
                </b-card>
              </b-col>
              <b-col md="8">
                <b-card>
                <table class="table b-table table-hover">
                  <thead>
                    <th>{{$t('First name')}}</th>
                    <th>{{$t('Last name')}}</th>
                    <th>{{$t('Country')}}</th>
                    <th>{{$t('City')}}</th>
                    <th>{{$t('Address')}}</th>
                  </thead>
                  <tbody>
                    <template v-for="(data, index) in addresses">
                      <tr :key="index">
                        <td>{{data.first_name}}</td>
                        <td>{{data.last_name}}</td>
                        <td>{{data.city.country.name}}</td>
                        <td>{{data.city.name}}</td>
                        <td>{{data.details}}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                </b-card>
              </b-col>
          </b-row>
      </transition>
      <b-row>
          <b-col md="12">
              <transition-group
              :name="$store.state.appConfig.layout.routerTransition"
              mode="out-in"
              >
                <div v-for="(data,index) in orders.data" :key="index">
                  <b-link :to="`/orders/`+data.id">
                    <b-card>
                      <b-row>
                        <b-col md="9" sm="9">
                          <div>
                            <b-form-checkbox name="checkbox">
                              <h3 class="text-primary">#{{ data.id }}</h3>
                            </b-form-checkbox>
                            <b-card-text class="text-gray">
                                {{ data.created_at | formatDate }}
                            </b-card-text>

                            <h3>
                              {{data.username}}
                            </h3>
                            <p class="text-secondary">
                              <b-link :href="'https://wa.me/'+data.mobile" target="_blank">
                                {{data.mobile}}
                                <b-img
                                    fluid
                                    :src="require('@/assets/images/icons/whatsapp.png')"
                                    alt="whatsapp"
                                    width="15px"
                                  />
                              </b-link>
                            </p>
                            <p class="text-secondary">
                              {{data.city.country.name}} - {{data.city.name}} - {{data.address_owner}}
                            </p>
                            <div>
                                <b-img
                                  :src="`https://cdn.mhd.sa/asset/images/providers/${data.payment_method.name_en}-logo.png`"
                                  height="50"
                                  class="ml-1 mr-1"
                                  />
                                <b-img
                                  :src="`https://cdn.mhd.sa/asset/images/providers/${data.shipping_method.name_en}-logo.png`"
                                  height="50"
                                  class="ml-1 mr-1"
                                  />
                            </div>
                          </div>
                        </b-col>
                        <b-col md="3" sm="3" align-self="center">
                          <div class="text-center">
                            <h1 class="text-primary">
                              {{data.total_price}}
                              <small>{{$t('SAR')}}</small>
                            </h1>
                            <div v-if="data.status" class="mt-3">
                              <h4>
                                <div v-if="data.status.id === 1 || data.status.id === 2 || data.status.id === 4">
                                    <b-badge variant="danger" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                                </div>
                                <div  v-else-if="data.status.id === 3">
                                    <b-badge variant="warning" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                                </div>
                                <div  v-else>
                                    <b-badge variant="success" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                                </div>
                              </h4>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-link>
                </div>
              </transition-group>
              <pagination :data="orders" @pagination-change-page="getOrdersByShopper"></pagination>
              <b-card
                v-if="orders.length == 0"
                class="text-center"
                >
                <span><strong>{{$t('Orders')}}  </strong>{{$t('content not found')}}.</span>
              </b-card>
          </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {
    quillEditor,
  },
  name: 'shopperShow',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      shopper: {},
      addresses: {},
      orders: {}
    }
  },
  mounted() {
      this.getShopper();
      this.getAddresses();
      this.getOrdersByShopper();
  },
  methods: {
    getShopper(){
        this.isLoading = true
        useJwt.post('/shoppers/get-by-id',{id: this.$route.params.id})
        .then((response) => {
          //console.log(response.data)
          this.shopper = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
    },
    getAddresses(){
        this.isLoading = true
        useJwt.post('/addresses/get-by-shopper-id',{shopper_id: this.$route.params.id})
        .then((response) => {
          console.log(response.data.data)
          this.addresses = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          //console.log(response);
        });
    },
    getOrdersByShopper() {
        this.isLoading = true
        useJwt.post('/orders/get-by-shopper-id',{shopper_id: this.$route.params.id})
        .then((response) => {
          //console.log(response.data.data)
          this.orders = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          //console.log(response);
        });
    }
  }
}
</script>
